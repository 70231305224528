import { withPrefix } from 'gatsby';
import React from 'react';
import { AlertTriangle } from 'react-feather';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layouts/layout';

function NotFoundPage() {
  return (
    <>
      <Helmet>
        {/* Global site tag (gtag.js) - Google Analytics  */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-1LB4M0W7V9" />
        <script src={withPrefix('js/script.js')} type="text/javascript" />
      </Helmet>
      <Layout>
        <div className="w-full flex items-center content-center justify-center flex-wrap lg:h-80 lg:container lg:mx-auto">
          <div className="w-full flex itmes-center justify-center text-5xl mb-10">
            <AlertTriangle className="w-12 h-12 mr-2" />
            404
          </div>
          <h2 className="w-full bg-yellow-400 text-2xl font-bold inline-block text-center">
            Page your are looking is not found.
          </h2>
        </div>
      </Layout>
    </>
  );
}

export default NotFoundPage;
